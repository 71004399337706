import { _ } from "core-js";

export default {
	computed: {
		...mapValues([
			'impairedEnable',
			'impairedFont',
			'impairedTheme',
			'impairedNoImages',
		]),

		impairedClasses() {
			if (!this.impairedEnable) {
				return [];
			}
			let classes = [];
			if (this.impairedTheme) {
				classes.push('impaired-theme-' + this.impairedTheme);
			}
			if (this.impairedNoImages) {
				classes.push('impaired-no-images');
			}
			if (window.addedBodyClasses) {
				_.forEach(window.addedBodyClasses, (v) => {
					$('body').removeClass(v);
				});
			}
			_.forEach(classes, (v) => {
				$('body').addClass(v);
			});
			window.addedBodyClasses = classes;
			return {};
		},

		impairedStyles() {
			console.log('q');
			if (!this.impairedEnable) {
				$("body")[0].style.setProperty(
					'--impaired-18', '18px',
				);
				return {};
			}
			$("body")[0].style.setProperty(
				'--impaired-18', (this.impairedFont || 18) + 'px',
			);
			return {};
		},
	},
}
