import Vue from 'vue';

// Vue.js
Vue.config.productionTip = false;  // Отключаем предупреждение о dev-версии, чтобы не засоряло консоль
Vue.config.devtools = false;  // Отключаем сообщение о devtools

// lodash
window._ = require('lodash');
Vue.prototype._ = _;

// Проброс данных состояния
window.mapValues = function (arr) {
	let list = [];
	_.forEach(arr, (v) => {
		list[v] = () => {
			return window.bus.state[v];
		}
	});
	return list;
};

// Глобальная шина для передачи данных и событиий между компонентами
window.bus = new Vue({
	data: {
		modalWindows: [],
		state: {},
	},
});

require('./main-init.js');
