<template>
	<div v-if="impairedEnable" class="impaired-panel impaired-ignore">
		<div class="t-wrapper">
			<div>
				<div>
					<div class="title-block">
						Размер шрифта: {{ impairedFont }}
					</div>
					<div class="controls">
						<div class="minus" @click="changeFont(-2)">–</div>
						<div class="plus" @click="changeFont(2)">+</div>
					</div>
				</div>
				<div>
					<div class="title-block">
						Цвет сайта:
					</div>
					<div class="controls">
						<div class="white" @click="switchTheme(null)"></div>
						<div class="black" @click="switchTheme('black')"></div>
					</div>
				</div>
				<div>
					<div class="title-block">
						Изображения:
					</div>
					<div class="controls">
						<div @click="switchNoImages(false)">Вкл.</div>
						<div @click="switchNoImages(true)">Выкл.</div>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div class="controls">
						<div class="impaired-switch2" @click="impairedSwitch">
							Обычная версия
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			fontSize: 18,
		};
	},

	computed: {
		...mapValues([
			'impairedEnable',
			'impairedFont',
			'impairedTheme',
			'impairedNoImages',
		]),
	},

	created() {
		this.$set(window.bus.state, 'impairedEnable', localStorage.getItem('impairedEnable') || false);
		this.$set(window.bus.state, 'impairedFont', parseInt(localStorage.getItem('impairedFont')) || 18);
		this.$set(window.bus.state, 'impairedTheme', localStorage.getItem('impairedTheme') || '');
		this.$set(window.bus.state, 'impairedNoImages', localStorage.getItem('impairedNoImages') || false);

		this.applyImpairedClass();

		window.bus.$on('impaired-switch', this.impairedSwitch);
	},

	methods: {
		applyImpairedClass() {
			$('body').toggleClass('impaired', this.impairedEnable);
		},

		impairedSwitch() {
			if (this.impairedEnable) {
				localStorage.removeItem('impairedEnable');
				this.$set(window.bus.state, 'impairedEnable', false);
			} else {
				localStorage.setItem('impairedEnable', '1');
				this.$set(window.bus.state, 'impairedEnable', true);
			}
			this.applyImpairedClass();
		},

		changeFont(val) {
			let impairedFont = this.impairedFont;
			impairedFont += val;
			if (impairedFont < 18) {
				impairedFont = 18;
			}
			if (impairedFont > 26) {
				impairedFont = 26;
			}
			this.$set(window.bus.state, 'impairedFont', impairedFont);
			if (impairedFont == 18) {
				localStorage.removeItem('impairedFont');
			} else {
				localStorage.setItem('impairedFont', impairedFont);
			}
		},

		switchTheme(val) {
			this.$set(window.bus.state, 'impairedTheme', val);
			if (!val) {
				localStorage.removeItem('impairedTheme');
			} else {
				localStorage.setItem('impairedTheme', val);
			}
		},

		switchNoImages(val) {
			this.$set(window.bus.state, 'impairedNoImages', val);
			if (!val) {
				localStorage.removeItem('impairedNoImages');
			} else {
				localStorage.setItem('impairedNoImages', 1);
			}
		},
	},
}
</script>
<style lang="scss" src="./ImpairedPanel.scss" />
