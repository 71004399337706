<template>
  <div id="app">
    <ImpairedPanel msg="Welcome to Your Vue.js App"/>
    <div :class="impairedClasses" :style="impairedStyles"></div>
    <a class="button-imp" @click="impairedSwitch">Версия для слабовидящих</a>
  </div>
</template>

<script>
import ImpairedPanel from './components/ImpairedPanel.vue'

import ImpairedMixin from '@/mixins/ImpairedMixin.js';

export default {
  name: 'App',
  components: {
    ImpairedPanel
  },
  mixins: [
    ImpairedMixin,
  ],
  methods: {
		impairedSwitch() {
			window.bus.$emit('impaired-switch');
    },
  },
}
</script>
